import { Link } from 'gatsby';
import React, { useState } from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import SubTitle from '../components/SubTitle';

const Label = ({ children }) => (
    <span>
        <span className="me">My</span>
        <span className="you">Your</span>
        <span>{children}</span>
    </span>
);

const ContactPage = () => {
    const [agree, setAgree] = useState(false);

    return (
        <Layout>
            <SEO
                title="Contact"
                description="Whatever you need, just let us know. Together we can do it!"
            />
            <section>
                <form
                    className="padded full-height form"
                    name="contact"
                    id="contact"
                    method="POST"
                    action="/message"
                    data-netlify="true"
                >
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="magazine">
                        <SubTitle>
                            <h1>Contact</h1>
                        </SubTitle>
                        <div className="sm-gap-top-lg description">
                            <label htmlFor="name">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Diego Barrera"
                                    required
                                />
                                <Label>Name</Label>
                            </label>
                            <label htmlFor="email">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="contact@castrum-doloris.design"
                                    required
                                />
                                <Label>Email</Label>
                            </label>
                            <label htmlFor="message">
                                <textarea
                                    id="message"
                                    name="message"
                                    placeholder="Hello there! Feel free to ask anything you want. I would love to hear from you!"
                                    required
                                />
                                <Label>Message</Label>
                            </label>

                            <label htmlFor="privacy" id="PrivacyInput">
                                <input
                                    type="checkbox"
                                    name="privacy"
                                    id="privacy"
                                    onChange={() => setAgree(!agree)}
                                />
                                <span>
                                    You agree with the
                                    <Link to="/privacy-policy" className="link">
                                        privacy policy
                                    </Link>
                                </span>
                            </label>
                            <div className="wrapper">
                                <button disabled={!agree} className="cta-button" type="submit">
                                    send
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </Layout>
    );
};

export default ContactPage;
